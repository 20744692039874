<template>
  <v-row>
    <v-col cols="4">
      <Form/>
    </v-col>
    <v-col cols="8">
      <List/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    Form: () => import("@/components/user/Form.vue"),
    List: () => import("@/components/user/List.vue")
  }
};
</script>